import React from 'react'
import angleBetweenPoints from 'angle-between-points'
import calcDistance from 'euclidean-distance'
import { saveAs } from 'file-saver'
import svgpath from 'svgpath'
import Path from 'svg-path-generator'
import clamp from 'ramda.clamp'
import Slider from '@material-ui/core/Slider'
import Switch from '@material-ui/core/Switch'

import svg_rows from './rows.svg'
import { NumberInput } from './NumberInput'

function App() {
  React.useEffect(() => {
    document.title = 'SVG Matrix'
  })

  const ref = React.useRef(null)
  const groupRef = React.useRef(null)

  const [lineWidth, setLineWidth] = React.useState(30)
  const [gap, setGap] = React.useState(10)

  const rotationReplica = React.useRef(135)
  const [rotation, setRotation] = React.useState(180)

  const [matrix, setMatrix] = React.useState({ y: 19, x: 19 })
  const [source, setSource] = React.useState({ y: 10, x: 10 })
  const [perspectiveShift, setPerspetiveShift] = React.useState(15)
  const [waves, setWaves] = React.useState(0)
  const [crosses, setCrosses] = React.useState(false)

  React.useEffect(() => {
    rotationReplica.current = rotation
  }, [rotation])

  const handleDownload = () => {
    const { width, height } = groupRef.current.getBoundingClientRect()

    const clone = ref.current.cloneNode(true)

    clone.setAttribute('viewBox', `0 0 ${width} ${height}`)
    clone.setAttribute('width', `${width}px`)
    clone.setAttribute('height', `${height}px`)

    const svg_data_uri = 'data:image/svg+xml;base64,' + btoa(clone.outerHTML)

    saveAs(svg_data_uri, 'matrix')
  }

  React.useEffect(() => {
    const { width, height } = groupRef.current.getBoundingClientRect()

    ref.current.setAttribute('viewBox', `0 0 ${width} ${height}`)
    ref.current.setAttribute('width', `${width}px`)
    ref.current.setAttribute('height', `${height}px`)
  }, [lineWidth, gap, rotation, matrix, source, perspectiveShift, waves, crosses])

  return (
    <div className='main'>
      <div className='container'>
        <div className='side'>
          <a className='site' href='https://101.lu'>
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 64 20'>
              <path
                fill='currentColor'
                d='M6.612.309-.001 5.883v7.736l6.613-5.557V20h7.428V.309H6.612ZM64 20V.309h-7.428l-6.63 5.574v7.736l6.63-5.557V20H64ZM44.747 20v-8.285C44.747 4.202 39.038 0 32.408 0c-6.63 0-12.392 4.202-12.392 11.715V20h7.428v-8.182c0-3.31 2.056-5.369 4.964-5.369 2.907 0 4.91 2.042 4.91 5.37V20h7.43Z'
              />
            </svg>
          </a>

          <h1>Matrix Generator</h1>

          <p class='m-only'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              class='feather feather-monitor'
            >
              <rect x='2' y='3' width='20' height='14' rx='2' ry='2'></rect>
              <line x1='8' y1='21' x2='16' y2='21'></line>
              <line x1='12' y1='17' x2='12' y2='21'></line>
            </svg>
            Check it out on a bigger screen
          </p>

          <h4 style={{ marginTop: '30px' }}>Line Length</h4>
          <div className='description'>Individual line length in %.</div>
          <div className='flex'>
            <NumberInput value={lineWidth} onChange={setLineWidth} />
          </div>

          <h4>Grid</h4>
          <div className='description'>Amount of rows and columns.</div>
          <div className='flex'>
            <label>
              <img
                src={svg_rows}
                style={{
                  width: '14px',
                  height: '14px',
                  marginRight: '8px',
                  transform: 'rotate(90deg)',
                }}
              />{' '}
              Rows
            </label>
            <NumberInput
              value={matrix.y}
              onChange={y => {
                setMatrix({ ...matrix, y })
              }}
            />
            <label>
              <img
                src={svg_rows}
                style={{
                  width: '14px',
                  height: '14px',
                  marginRight: '8px',
                }}
              />
              Columns
            </label>
            <NumberInput
              value={matrix.x}
              onChange={x => {
                setMatrix({ ...matrix, x })
              }}
            />
          </div>

          <h4>Origin</h4>
          <div className='description'>Transform origin.</div>
          <div className='flex'>
            <label>Y</label>
            <NumberInput
              value={source.y}
              onChange={y => {
                setSource({ ...source, y })
              }}
            />
            <label>X</label>
            <NumberInput
              value={source.x}
              onChange={x => {
                setSource({ ...source, x })
              }}
            />
          </div>

          <h4>Gap</h4>
          <div className='description'>The gap between lines in px.</div>

          <div className='flex'>
            <NumberInput value={gap} onChange={setGap} />
          </div>

          <div className='flex margin' style={{ alignItems: 'center' }}>
            <h4 style={{ margin: '0px', flex: 1 }}>Crosses</h4>
            <Switch
              style={{ flex: '0 ' }}
              checked={crosses}
              onChange={e => {
                setCrosses(e.target.checked)
              }}
            />
          </div>

          <div className='flex ' style={{ alignItems: 'center' }}>
            <h4 style={{ margin: '0px', flex: 1 }}>Wave</h4>

            <Switch
              style={{ flex: '0 ' }}
              checked={waves === 1}
              onChange={e => {
                setWaves(e.target.checked ? 1 : 0)
              }}
            />
          </div>
          <div className='description' style={{ marginTop: '0px' }}>
            Alternative wave around origin.
          </div>

          <div className='flex' style={{ alignItems: 'center' }}>
            <h4 style={{ margin: '0px', flex: 1 }}>Perspective</h4>
            <Switch
              style={{ flex: '0 ' }}
              checked={perspectiveShift !== 0}
              onChange={e => {
                setPerspetiveShift(e.target.checked ? 10 : 0)
              }}
            />
          </div>

          <div style={{ opacity: perspectiveShift !== 0 ? 1 : 0.3 }}>
            <h4>Perspective</h4>
            <div className='description'>
              Currently <b>-{perspectiveShift}</b>
            </div>
            <Slider
              style={{ width: '100%' }}
              min={0}
              max={100}
              value={perspectiveShift}
              onChange={(e, newValue) => {
                setPerspetiveShift(+newValue)
              }}
            />
          </div>

          <h4>Rotation</h4>
          <div className='description'>
            Rotation before any transformation is applied. Currently transform(
            <b>{rotation}deg</b>)
          </div>

          <Slider
            style={{ width: '100%' }}
            min={0}
            max={360}
            value={rotation}
            onChange={(e, newValue) => {
              setRotation(+newValue)
            }}
          />
        </div>
        <div className='result-container'>
          <div className='result'>
            <div className='actions'>
              <a className='hiring' href='https://101.lu/jobs' target='_blank'>
                🎓 Jobless? We're hiring!
              </a>
              <button onClick={handleDownload}>Export as SVG</button>
            </div>

            <svg
              width='100%'
              height='100%'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              stroke='currentColor'
              ref={ref}
            >
              <g stroke='#fff' strokeWidth='1.5' strokeLinecap='round' ref={groupRef}>
                {Array.from(Array(matrix.y).keys()).map(yIndex =>
                  Array.from(Array(matrix.x).keys()).map(xIndex => {
                    const xStart = xIndex * (lineWidth + gap)
                    const yStart = yIndex * (lineWidth + gap)

                    const distance = calcDistance([xIndex, yIndex], [source.x - 1, source.y - 1])

                    const widthPercent = distance / perspectiveShift
                    const bottomScale = clamp(
                      0,
                      1,
                      waves ? 1 - widthPercent : (1 - widthPercent) / 2
                    )

                    const angle = Math.round(
                      angleBetweenPoints(
                        { x: xIndex, y: yIndex },
                        { y: source.y - 1, x: source.x - 1 }
                      )
                    )

                    return (
                      <>
                        <path
                          d={svgpath(
                            Path()
                              .moveTo(
                                xStart + (perspectiveShift ? lineWidth * bottomScale : 0),
                                yStart + (perspectiveShift ? lineWidth * bottomScale : 0)
                              )
                              .lineTo(
                                xStart +
                                  lineWidth -
                                  (perspectiveShift ? lineWidth * bottomScale : 0),
                                yStart +
                                  lineWidth -
                                  (perspectiveShift ? lineWidth * bottomScale : 0)
                              )
                              .end()
                          ).rotate(
                            angle + rotation,
                            xStart + lineWidth / 2,
                            yStart + lineWidth / 2
                          )}
                          style={{
                            stroke:
                              source.y - 1 === yIndex && source.x - 1 === xIndex ? '#000' : '#000',
                          }}
                        />
                        {crosses && (
                          <path
                            d={svgpath(
                              Path()
                                .moveTo(
                                  xStart + (perspectiveShift ? lineWidth * bottomScale : 0),
                                  yStart + (perspectiveShift ? lineWidth * bottomScale : 0)
                                )
                                .lineTo(
                                  xStart +
                                    lineWidth -
                                    (perspectiveShift ? lineWidth * bottomScale : 0),
                                  yStart +
                                    lineWidth -
                                    (perspectiveShift ? lineWidth * bottomScale : 0)
                                )
                                .end()
                            ).rotate(
                              angle + rotation + 90,
                              xStart + lineWidth / 2,
                              yStart + lineWidth / 2
                            )}
                            style={{
                              stroke:
                                source.y - 1 === yIndex && source.x - 1 === xIndex
                                  ? '#000'
                                  : '#000',
                            }}
                          />
                        )}
                      </>
                    )
                  })
                )}
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
