import React from 'react'
import { G, S } from '@mobily/ts-belt'
import clsx from 'clsx'

const formatNumber = n => n.replaceAll(',', '.')

export const NumberInput = ({
  decimalPoint = 1,
  min = -Number.MAX_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  touched = true,
  ...props
}) => {
  const inputRef = React.useRef(null)
  const unitRef = React.useRef(null)

  const [unitWidth, setUnitWidth] = React.useState(0)
  const [unsafeValue, setUnsafeValue] = React.useState(() => S.make(props.value))

  React.useEffect(() => setUnsafeValue(S.make(props.value)), [props.value])

  React.useEffect(() => {
    if (unitRef.current) setUnitWidth(unitRef.current.getBoundingClientRect().width)
  }, [props.unit])

  React.useEffect(() => {
    // update safe number
    const unsafeNumber = +unsafeValue
    const number = G.isNumber(unsafeNumber) ? unsafeNumber : 0

    const validNumber = Math.min(max, Math.max(min, number))

    const roundedNumber = decimalPoint
      ? Math.round(validNumber * (1 / decimalPoint)) / (1 / decimalPoint)
      : validNumber

    props.onChange(roundedNumber)
  }, [unsafeValue])

  const handleBlur = () => {
    // update safe number
    const unsafeNumber = +unsafeValue
    const number = G.isNumber(unsafeNumber) ? unsafeNumber : 0

    const validNumber = Math.min(max, Math.max(min, number))

    const roundedNumber = decimalPoint
      ? Math.round(validNumber * (1 / decimalPoint)) / (1 / decimalPoint)
      : validNumber

    setUnsafeValue(S.make(roundedNumber))
    props.onChange(roundedNumber)
  }

  return (
    <input
      ref={inputRef}
      onFocus={e => e.target.select()}
      className={clsx(
        touched ? 'text-black' : 'text-black/40',
        'py-3 px-4 w-full min-w-0 flex rounded-lg text-sm bg-gray-100 font-semibold focus:text-blue'
      )}
      value={unsafeValue}
      onChange={e => setUnsafeValue(formatNumber(e.target.value))}
      onKeyDown={e => {
        const unsafeNumber = +unsafeValue
        const number = G.isNumber(unsafeNumber) ? unsafeNumber : 0

        if (e.code === 'ArrowUp') {
          e.preventDefault()
          e.stopPropagation()
        }

        if (e.code === 'ArrowDown') {
          e.preventDefault()
          e.stopPropagation()
        }

        if (e.key === 'Enter') {
          inputRef.current?.blur()
          inputRef.current?.focus()
        }
      }}
      onBlur={handleBlur}
      style={{ paddingRight: `calc(${unitWidth}px + 1.1rem)` }}
    />
  )
}
